import React, { Component } from "react"
import Layout from "../components/layout/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Content from "../components/utility/Content/Content"
import Contact from "../components/mainPageSections/contact/contact"
import Team from "../components/mainPageSections/team/team"
import About from "../components/mainPageSections/about/about"
import Ip from "../components/mainPageSections/ip/ip"
import Portfolio from "../components/mainPageSections/portfolio/portfolio"
import ScrollContainer from "../components/autoScrollNav/scrollContainer"
import ScrollPageSection from "../components/autoScrollNav/scrollPageSection"
import Header from "../components/layout/header.backup"
import ScrollHeader from "../components/autoScrollNav/scrollHeader"
import Footer from "../components/layout/footer"
import Landing from "../components/mainPageSections/landing/landing"

class ContactMain extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const siteSettings = this.props.data.siteSettings.edges[0].node.acf
    return (
      <Layout siteSettings={siteSettings}>
        <SEO title="Contact" />
        <Contact
          logo={siteSettings.full_logo}
          data={this.props.data.contact.edges[0].node}
        />
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    contact: allWordpressPage(filter: { title: { regex: "/Contact/" } }) {
      edges {
        node {
          title
          wordpress_id
          acf {
            address
            email
          }
        }
      }
    }

    siteSettings: allWordpressPage(
      filter: { title: { regex: "/Whole Site Settings/" } }
    ) {
      edges {
        node {
          title
          wordpress_id
          acf {
            full_logo {
              alt_text
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            full_logo_dark {
              alt_text
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            square_logo {
              alt_text
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ContactMain
